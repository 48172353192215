<template>
  <div>
    <dt class="text-sm font-medium text-gray-500 pb-2 border-b">
      {{ label }}
    </dt>
    <ul v-if="name" class="space-y-3 pt-2">
      <li class="flex justify-start">
        <a href="#" class="flex items-center space-x-3">
          <div v-if="avatar" class="flex-shrink-0">
            <img class="h-5 w-5 rounded-full" :src="avatar" alt="">
          </div>
          <div v-if="isIcon && !avatar">
            <div class="h-5 w-5 rounded-full bg-gray-200 flex items-center text-10 text-center">
              <span v-if="!isDate" class="block w-full">{{ name | getNameAbbr }}</span>
              <span v-else class="block w-full">{{ name }}</span>
            </div>
          </div>
          <div v-if="!isDate" class="mt-1 text-sm text-gray-900">{{ name }}</div>
          <div v-else class="mt-1 text-sm text-gray-900">{{ name | parseDateMain }}</div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SelectOptionLabel',
  props: {
    label: {
      type: String,
      required: true
    },
    name: {
      required: true
    },
    avatar: {
      type: String,
      'default': null
    },
    isIcon: {
      type: Boolean,
      'default': true
    },
    isDate: {
      type: Boolean,
      'default': false
    }
  }
}
</script>

<style scoped>

</style>
