<template>
  <div class="xl:col-span-1 xl:pr-8 xl:border-r xl:border-gray-200 xl:pl-8">
    <div>
      <h1 class="text-lg font-medium text-gray-900">
        {{ $t('invoice.invoiceInformation') }}
      </h1>
    </div>
    <div class="mt-6 border-t border-gray-200 py-6 space-y-8">
      <table class="w-full">
        <tbody>
        <tr>
          <td class="border-r text-sm font-medium text-gray-500 border-b p-2.5">{{ $t('invoice.invoice') }} #</td>
          <td class="border-b text-sm text-gray-900 pl-2">{{ id }}</td>
        </tr>
        <tr>
          <td class="border-r text-sm font-medium text-gray-500 border-b p-2.5">{{ $t('invoice.created') }}</td>
          <td class="border-b text-sm text-gray-900 pl-2">{{ createdAt | parseDateMain}}</td>
        </tr>
        <tr>
          <td class="border-r text-sm font-medium text-gray-500 border-b p-2.5">{{ $t('invoice.sent') }}</td>
          <td v-if="status!=='draft' " class="border-b text-sm text-gray-900 pl-2">
            {{ sentAt | parseDateMain }}
          </td>
        </tr>
        <tr>
          <td class="border-r text-sm font-medium text-gray-500 border-b p-2.5">{{
              $t('invoice.fields.remoteId')
            }}
          </td>
          <td v-if="status!=='draft' " class="border-b text-sm text-gray-900 pl-2">{{ remoteId }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InvoiceInformation',
  props: {
    createdAt: {
      type: String,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    remoteId: {
      type: String
      // required: true
    },
    sentAt: {
      type: String | null
      // required: true
    }
  }
}
</script>

<style scoped>

</style>
