<template>
  <div class="xl:col-span-1 xl:pr-8 xl:border-r xl:border-gray-200">
    <div>
      <div>
        <div class="md:flex md:items-center md:justify-between md:space-x-4 xl:border-b xl:pb-6">
          <div>
            <h1 class="text-lg font-medium text-gray-900">
              {{ $t('invoice.customerInformation') }}
            </h1>
          </div>
        </div>
        <div class="py-3 xl:pt-6 xl:pb-0">
          <div class="prose max-w-none">
            <table class="w-full">
              <tbody>
              <tr>
                <td class="border-r border-b p-2.5 text-sm font-medium text-gray-500">{{ $t('invoice.customer.name') }}</td>
                <td v-if="task && task.customer" class="border-b text-sm text-gray-900 pl-2">{{ task.customer.name ? task.customer.name : '' }}</td>
              </tr>
              <tr>
                <td class="border-r border-b p-2.5 text-sm font-medium text-gray-500">{{ $t('invoice.customer.address') }}</td>
                <td v-if="task && task.customer" class="border-b text-sm text-gray-900 pl-2">{{ task.customer.address }}</td>
              </tr>
              <tr>
                <td class="border-r border-b p-2.5 text-sm font-medium text-gray-500">{{ $t('invoice.customer.contactPerson') }}</td>
                <td v-if="task && task.customer" class="border-b text-sm text-gray-900 pl-2">{{ task.customer.contact }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomerInformation',
  props: {
    task: {
      type: Object,
      'default': Object
    }
  }
}
</script>

<style scoped>

</style>
