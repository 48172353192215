<template>
  <thead class="border-t border-gray-200 border-b bg-gray-50 text-left text-xs text-gray-500 uppercase tracking-wider ">
    <tr>
      <th class="px-2 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
        {{ $t('invoice.invoiceRowsProduct') }}
      </th>
      <th class="px-2 py-3 bg-gray-50 text-xs font-medium text-gray-500 uppercase tracking-wider">
        {{ $t('invoice.invoiceRowsDeliveredBy') }}
      </th>
      <th class="hidden px-2 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:table-cell">
        {{ $t('invoice.invoiceRowsDeliveredAt') }}
      </th>
      <th class="px-2 py-3 bg-gray-50 text-xs font-medium text-gray-500 uppercase tracking-wider">
        {{ $t('invoice.invoiceRowsDescription') }}
      </th>
      <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
        {{ $t('invoice.invoiceRowsQuantity') }}
      </th>
      <th class="px-2 py-3 bg-gray-50 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
        {{ $t('invoice.invoiceRowsUnit') }}
      </th>
      <th class="px-2 py-3 bg-gray-50 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
        {{ $t('invoice.invoiceRowsUnitPrice') }}
      </th>
      <th class="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
        {{ $t('invoice.invoiceRowsVat') }}
      </th>
      <th class="px-2 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
        {{ $t('invoice.invoiceRowsTotal') }}
    </th>
      <th class="w-40p" />
  </tr>
  </thead>
</template>

<script>
export default {
  name: 'TableHead'
}
</script>

<style scoped>

</style>
