<template>
    <div v-if="!loading">
        <div class="px-4 sm:px-6 xl:grid xl:grid-cols-2">
            <CustomerInformation
                :task="invoice.task"
            />
            <InvoiceInformation
                :status="invoice.status"
                :createdAt="invoice.created_at"
                :id="invoice.id"
                :ourReference="invoice.our_reference"
                :yourReference="invoice.your_reference"
                :sentAt="invoice.sent_at"
                :remoteId="invoice.netvisor_id"
            />
        </div>
        <InvoiceRows :lines="invoice.lines"/>
        <div class="mt-5 grid md:grid-cols-3 px-4 sm:px-6">
            <div class="col-start-3">
                <InvoiceTotal :total="total"/>
                <div class="w-full mt-5 flex justify-end">
                    <MainButton
                        :class="{'cursor-not-allowed': invoice.status === 'sent' }"
                        :disabled="invoice.status === 'sent'"
                        :name="$t('task.approveAndSend')"
                        @clickAction="handleSendInvoice"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import InvoiceInformation from '@/components/Invoice/InvoiceInformation';
import CustomerInformation from '@/components/Invoice/CustomerInformation';
import InvoiceRows from '@/components/Invoice/InvoiceRows/InvoiceRows';
import InvoiceTotal from '@/components/Task/InvoiceTotal';
import MainButton from '@/components/Main/Buttons/MainButton/MainButton';

export default {
    name: 'Invoice',
    components: {
        MainButton,
        InvoiceTotal,
        InvoiceRows,
        CustomerInformation,
        InvoiceInformation
    },
    data() {
        return {
            loading: true
        };
    },
    computed: {
        ...mapGetters(['invoice']),
        total() {
            return {
                subtotal: this.invoice.total_vat_excluded,
                vat: this.invoice.total_vat,
                total: this.invoice.total_vat_included
            };
        }
    },
    watch: {
        invoice(newValue) {
            if (Object.keys(newValue).length) {
                this.loading = false;
                this.handleSetBreadCrumbItem(newValue.name);
            }
        }
    },
    mounted() {
        this.handleSetBreadCrumbItems({ index: 0, to: '/invoices' });
        this.handleSetBreadCrumbItems({ index: 1, to: '' });
        const { id } = this.$route.params;
        id && this.handleFetchInvoice(id);
    },
    methods: {
        ...mapActions({
            handleFetchInvoice: 'fetchInvoice',
            handleSetBreadCrumbItem: 'setBreadCrumbItem',
            handleFetchSendInvoice: 'fetchSendInvoice',
            handleSetBreadCrumbItems: 'setBreadCrumbItems',
            handleResetBreadCrumbItems: 'resetBreadCrumbItems'
        }),
        async handleSendInvoice() {
            const response = await this.handleFetchSendInvoice(this.invoice.id);

            if (response.status === 200){
                this.$toast.success('Sent!').toString();

                await this.handleFetchInvoice(this.invoice.id);
            } else {
                this.$toast.error('Something went wrong').toString();
            }
        }
    },
  destroyed() {
      this.handleResetBreadCrumbItems();
  }
};
</script>

<style scoped>

</style>
