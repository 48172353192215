<template>
  <div class="hidden sm:block">
    <div class="flex flex-col mt-2 align-middle inline-block min-w-full border-b border-gray-200">
      <table class="w-full table-fixed invoice_rows_table">
        <TableHead />
        <TableBody
            :lines="lines"
            @handleOpenDeleteCustomerModal="$emit('handleOpenDeleteCustomerModal', $event)"/>
      </table>
    </div>
  </div>
</template>

<script>

import TableHead from '@/components/Invoice/InvoiceRows/Table/TableHead';
import TableBody from '@/components/Invoice/InvoiceRows/Table/TableBody';

export default {
  name: 'DeskTopTable',
  components: {
    TableBody,
    TableHead
  },
  props: {
    lines: {
      type: Array,
      required: true
    }
  }
}
</script>

<style>
    .invoice_rows_table th{
        vertical-align:top;
    }
</style>
