<template>
  <thead class="border-t border-gray-200 border-b bg-gray-50 text-left text-xs text-gray-500 uppercase tracking-wider">
  <tr>
    <th
        class="whitespace-nowrap py-3 w-100p"
    >
      <div class="flex items-end justify-end">
        <div class="relative flex items-center flex-start pr-6 font-medium">
          {{ $t('global.period') }}
        </div>
      </div>
    </th>
    <th>
      <div class="flex justify-end cursor-pointer" @click="result ? handleSort('delivered_by_name') : ''">
        <div class="relative flex items-center flex-start pr-6 font-medium">
          {{ $t('global.deliveredBy') }}
          <ArrowSolidIcon v-if="result && selectedSortingKey === 'delivered_by_name'" :arrowUp="!arrowSortUp['delivered_by_name']"/>
        </div>
      </div>
    </th>
    <th
        class="whitespace-nowrap py-3 hidden md:table-cell"
    >
      <div class="flex items-end justify-end cursor-pointer" @click="result ? handleSort('product_name') : ''">
        <div class="relative flex items-center flex-start pr-6 font-medium">
          {{ $t('global.productName') }}
          <ArrowSolidIcon v-if="result && selectedSortingKey === 'product_name'" :arrowUp="!arrowSortUp['product_name']"/>
        </div>
      </div>
    </th>
    <th class="whitespace-nowrap py-3">
      <div class="flex items-end justify-end">
        <div class="relative flex items-center flex-end pr-6 font-medium">
          {{ $t('global.productAmount') }}
<!--          <ArrowSolidIcon v-if="selectedSortingKey === 'product_amount'" :arrowUp="!arrowSortUp['product_amount']"/>-->
        </div>
      </div>
    </th>
    <!--    <th>-->
    <!--      <div class="flex justify-end cursor-pointer" @click="handleSort('customer_name')">-->
    <!--        <div class="relative flex items-center flex-start pr-6 font-medium">-->
    <!--          {{ $t('invoice.fields.customer') }}-->
    <!--          <ArrowSolidIcon v-if="selectedSortingKey === 'customer_name'" :arrowUp="!arrowSortUp['customer_name']"/>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </th>-->
    <!--    <th class="whitespace-nowrap w-100p">-->
    <!--      <div class="flex justify-end cursor-pointer" @click="handleSort('status')">-->
    <!--        <div class="relative flex items-center flex-start pr-6 font-medium">-->
    <!--          {{ $t('invoice.fields.status') }}-->
    <!--          <ArrowSolidIcon v-if="selectedSortingKey === 'status'"-->
    <!--                          :arrowUp="!arrowSortUp['status']"/>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </th>-->
    <!--    <th class="whitespace-nowrap hidden xl:table-cell w-120p">-->
    <!--      <div class="flex justify-end w-full cursor-pointer" @click="handleSort('total_vat_included')">-->
    <!--        <div class="relative flex items-center flex-start pr-6 font-medium">-->
    <!--          {{ $t('invoice.fields.total') }}-->
    <!--          <ArrowSolidIcon v-if="selectedSortingKey === 'total_vat_included'"-->
    <!--                          :arrowUp="!arrowSortUp['total_vat_included']"/>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </th>-->

    <th class="w-50p"/>
  </tr>
  </thead>
</template>

<script>
import ArrowSolidIcon from '@/components/Icons/ArrowSolidIcon';

export default {
  name: 'InvoiceSummaryTableHead',
  components: {
    ArrowSolidIcon
  },
  props: {
    result: {
      type: Boolean
    }
  },
  data() {
    return {
      arrowSortUp: {
        id: false,
        product_name: false,
        product_amount: false,
        task_title: false,
        delivered_by_name: false
      },
      selectedSortingKey: 'product_name',
      isOpen: false
    };
  },
  methods: {
    handleSort(key) {
      this.selectedSortingKey = key;
      this.$emit('handleSort', key, this.arrowSortUp[key]);
      this.arrowSortUp[key] = !this.arrowSortUp[key];
    },
    handleOpenDropDown() {
      this.isOpen = !this.isOpen;
    },
    handleSelect(value) {
      this.isOpen = false;
      this.$emit('handleSelect', value);
    }

  }
};
</script>

<style scoped>

</style>
