var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"cursor-pointer",on:{"mouseover":function($event){return _vm.mouseOver(true)},"mouseleave":function($event){return _vm.mouseLeave(false)},"click":_vm.goToInvoice}},[_c('td',{staticClass:"px-7 py-3 whitespace-nowrap text-sm font-medium text-gray-900 w-80p"},[_c('div',{staticClass:"flex items-center"},[_c('input',{staticClass:"h-4 w-4 text-gray-600 focus:ring-0 rounded cursor-pointer",attrs:{"type":"checkbox"},domProps:{"checked":_vm.selectChecked(_vm.invoice)},on:{"click":function($event){$event.stopPropagation();return _vm.handleAdd.apply(null, arguments)}}})])]),_c('td',{staticClass:"px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-right",class:{
          'w-60p': _vm.$i18n.locale === 'en',
          'w-150p': _vm.$i18n.locale === 'fi'
      }},[_c('span',{staticClass:"text-gray-500"},[_vm._v(_vm._s(_vm.invoice.id))])]),_c('td',{staticClass:"px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-500 text-right truncate hidden md:table-cell",class:{
          'w-120p': _vm.$i18n.locale === 'en',
          'w-150p': _vm.$i18n.locale === 'fi'
      }},[_c('span',[_vm._v(_vm._s(_vm.invoice.netvisor_id))])]),_c('td',{staticClass:"px-6 py-3 text-sm text-gray-500 whitespace-nowrap truncate text-right hidden xl:table-cell w-150p"},[_c('span',[_vm._v(_vm._s(_vm._f("parseDateMain")(_vm.invoice.created_at)))])]),_c('td',{staticClass:" px-6 py-3 text-sm text-gray-500 whitespace-nowrap text-right truncate"},[_c('span',{},[_vm._v(_vm._s(_vm.invoice.task && _vm.invoice.task.title))])]),_c('td',{staticClass:" px-6 py-3 text-sm text-gray-500 text-right truncate"},[_c('span',[_vm._v(_vm._s(_vm.invoice.task && _vm.invoice.task.customer && _vm.invoice.task.customer.name))])]),_c('td',{staticClass:" px-7 py-3 whitespace-nowrap text-sm text-gray-500 text-right w-150p"},[_c('span',{class:['inline-flex items-center justify-center text-white px-2 py-1 text-xs leading-none rounded', {
      'bg-success': _vm.invoice.status === 'sent',
      'bg-gray-400': _vm.invoice.status !== 'sent'
    }]},[_vm._v(_vm._s(_vm.invoice.status))])]),_c('td',{staticClass:" px-7 py-3 whitespace-nowrap text-sm text-gray-500 text-right hidden xl:table-cell w-150p"},[_c('span',[_vm._v(_vm._s(_vm.invoice.total_vat_included))]),_vm._v(" "),_c('span',[_vm._v("€")])]),_c('td',[(_vm.invoice.status === 'draft' && (_vm.showEditable || (_vm.selectedItem === String(_vm.invoice.id))))?_c('EditActions',{attrs:{"hideEdit":false,"item":_vm.invoice,"itemType":"invoice"},on:{"handleOpenDeleteItemModal":_vm.handleOpenDeleteItemModal,"handleSelectItem":_vm.handleSelectItem}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }