<template>
  <tbody class="bg-white divide-y divide-gray-200">
    <Row
        v-for="line in lines"
        :key="line.id"
        :line="line"
        @handleOpenDeleteItemModal="$emit('handleOpenDeleteCustomerModal', line.id)"
        @handleSetSelectedItem="handleSetSelectedItem"
        :selectedItem="String(selectedItem)"
    />
  </tbody>
</template>

<script>
import EditActions from '@/components/Invoice/EditActions/EditActions';
import Row from '@/components/Invoice/InvoiceRows/Table/Row';

export default {
  name: 'TableBody',
  components: {
    Row,
    EditActions
  },
  props: {
    lines: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedItem: ''
    }
  },
  methods: {
    handleSetSelectedItem(invoiceId) {
      this.selectedItem = invoiceId;
    }
  }
}
</script>

<style scoped>

</style>
