<template>
    <thead class="border-t border-gray-200 border-b bg-gray-50 text-left text-xs text-gray-500 uppercase tracking-wider">
        <tr>
            <th class="w-80p">
                <div class="whitespace-nowrap px-6 py-3 relative cursor-pointer max-w-150p  ">
                    <div @click.prevent="handleOpenDropDown" class=" px-4 py-2.5 bg-no-repeat">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4"/>
                        </svg>
                    </div>
                </div>
                <div
                    v-if="isOpen"
                    class="mx-3 absolute w-48 mt-1 rounded-md shadow-lg z-10 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
                >
                    <div class="py-1">
                        <p @click.prevent="handleSelect('all')"
                           class="flex items-center px-4 py-2 cursor-pointer text-gray-700 capitalize text-sm hover:bg-gray-100 hover:text-gray-900">
                            {{ $t('invoice.selectAll') }}
                        </p>
                    </div>
                    <div class="py-1">
                        <p @click.prevent="handleSelect('draft')"
                           class="flex items-center capitalize text-sm cursor-pointer px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900">
                            {{ $t('invoice.selectDraft') }}
                        </p>
                    </div>
                    <div class="py-1">
                        <p @click.prevent="handleSelect('unselect')"
                           class="flex items-center capitalize text-sm cursor-pointer px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900">
                            {{ $t('invoice.unselect') }}
                        </p>
                    </div>
                </div>
            </th>
            <th
                class="whitespace-nowrap py-3"
                :class="{
                    'w-60p': $i18n.locale === 'en',
                    'w-150p': $i18n.locale === 'fi'
                }"
            >
                <div class="flex items-end justify-end cursor-pointer" @click="handleSort('id')">
                    <div class="relative flex items-center flex-start pr-6 font-medium">
                        {{ $t('invoice.fields.id') }}
                        <ArrowSolidIcon v-if="selectedSortingKey === 'id'" :arrowUp="!arrowSortUp['id']"/>
                    </div>
                </div>
            </th>
            <th
                class="whitespace-nowrap py-3 hidden md:table-cell"
                :class="{
                    'w-120p': $i18n.locale === 'en',
                    'w-150p': $i18n.locale === 'fi'
                }"
            >
                <div class="flex items-end justify-end cursor-pointer" @click="handleSort('netvisor_id')">
                    <div class="relative flex items-center flex-start pr-6 font-medium">
                        {{ $t('invoice.fields.remoteId') }}
                        <ArrowSolidIcon v-if="selectedSortingKey === 'netvisor_id'" :arrowUp="!arrowSortUp['netvisor_id']"/>
                    </div>
                </div>
            </th>
            <th class="whitespace-nowrap py-3 hidden xl:table-cell w-150p">
                <div class="flex items-end justify-end cursor-pointer" @click="handleSort('created_at')">
                    <div class="relative flex items-center flex-end pr-6 font-medium">
                        {{ $t('invoice.fields.date') }}
                        <ArrowSolidIcon v-if="selectedSortingKey === 'created_at'" :arrowUp="!arrowSortUp['created_at']"/>
                    </div>
                </div>
            </th>
            <th>
                <div class="flex justify-end cursor-pointer" @click="handleSort('task_title')">
                    <div class="relative flex items-center flex-start pr-6 font-medium">
                        {{ $t('invoice.fields.task') }}
                        <ArrowSolidIcon v-if="selectedSortingKey === 'task_title'" :arrowUp="!arrowSortUp['task_title']"/>
                    </div>
                </div>
            </th>
            <th>
                <div class="flex justify-end cursor-pointer" @click="handleSort('customer_name')">
                    <div class="relative flex items-center flex-start pr-6 font-medium">
                        {{ $t('invoice.fields.customer') }}
                        <ArrowSolidIcon v-if="selectedSortingKey === 'customer_name'" :arrowUp="!arrowSortUp['customer_name']"/>
                    </div>
                </div>
            </th>
            <th class="whitespace-nowrap w-100p">
                <div class="flex justify-end cursor-pointer" @click="handleSort('status')">
                    <div class="relative flex items-center flex-start pr-6 font-medium">
                        {{ $t('invoice.fields.status') }}
                        <ArrowSolidIcon v-if="selectedSortingKey === 'status'"
                                        :arrowUp="!arrowSortUp['status']"/>
                    </div>
                </div>
            </th>
            <th class="whitespace-nowrap hidden xl:table-cell w-120p">
                <div class="flex justify-end w-full cursor-pointer" @click="handleSort('total_vat_included')">
                    <div class="relative flex items-center flex-start pr-6 font-medium">
                        {{ $t('invoice.fields.total') }}
                        <ArrowSolidIcon v-if="selectedSortingKey === 'total_vat_included'"
                                        :arrowUp="!arrowSortUp['total_vat_included']"/>
                    </div>
                </div>
            </th>

            <th class="w-50p" />
        </tr>
    </thead>
</template>

<script>
import ArrowSolidIcon from '@/components/Icons/ArrowSolidIcon';

export default {
    name: 'TableHead',
    components: {
        ArrowSolidIcon
    },
    data() {
        return {
            arrowSortUp: {
                id: false,
                netvisor_id: false,
                created_at: false,
                task_title: false,
                customer_name: false,
                total_vat_included: false,
                status: false
            },
            selectedSortingKey: 'id',
            isOpen: false
        };
    },
    methods: {
        handleSort(key) {
            this.selectedSortingKey = key;
            this.$emit('handleSort', key, this.arrowSortUp[key]);
            this.arrowSortUp[key] = !this.arrowSortUp[key];
        },
        handleOpenDropDown() {
            this.isOpen = !this.isOpen;
        },
        handleSelect(value) {
            this.isOpen = false;
            this.$emit('handleSelect', value);
        }

    }
};
</script>

<style scoped>

</style>
