<template>
  <tbody class="bg-white divide-y divide-gray-200">
    <InvoiceSummaryRow
        v-for="line in lines"
        :key="line.id"
        :invoiceLine="line"
    />
  </tbody>
</template>

<script>
import EditActions from '@/components/Invoice/EditActions/EditActions';
import InvoiceSummaryRow from '@/components/Invoice/Table/TableBody/Row/InvoiceSummaryRow';

export default {
  name: 'InvoiceSummaryTableBody',
  components: {
    InvoiceSummaryRow,
    EditActions
  },
  props: {
    lines: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
    }
  },
  methods: {
    /*handleSetSelectedItem(invoiceId) {
      this.selectedItem = invoiceId;
    }*/
  }
}
</script>

<style scoped>

</style>
