<template>
  <tr
      @mouseover="mouseOver(true)"
      @mouseleave="mouseLeave(false)"
      @click="goToInvoice"
      class="cursor-pointer"
  >
    <td class="px-7 py-3 whitespace-nowrap text-sm font-medium text-gray-900 w-80p">
      <div class="flex items-center">
        <input
          @click.stop="handleAdd"
          type="checkbox"
          :checked="selectChecked(invoice)"
          class="h-4 w-4 text-gray-600 focus:ring-0 rounded cursor-pointer">
      </div>
    </td>
    <td
        class="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 text-right"
        :class="{
            'w-60p': $i18n.locale === 'en',
            'w-150p': $i18n.locale === 'fi'
        }"
    >
      <span class="text-gray-500">{{ invoice.id }}</span>
    </td>
    <td
        class="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-500 text-right truncate hidden md:table-cell"
        :class="{
            'w-120p': $i18n.locale === 'en',
            'w-150p': $i18n.locale === 'fi'
        }"
    >
      <span>{{ invoice.netvisor_id }}</span>
    </td>
    <td class="px-6 py-3 text-sm text-gray-500 whitespace-nowrap truncate text-right hidden xl:table-cell w-150p">
      <span>{{ invoice.created_at | parseDateMain }}</span>
    </td>
    <td class=" px-6 py-3 text-sm text-gray-500 whitespace-nowrap text-right truncate">
      <span class="">{{ invoice.task && invoice.task.title }}</span>
    </td>
    <td class=" px-6 py-3 text-sm text-gray-500 text-right truncate">
      <span>{{ invoice.task && invoice.task.customer && invoice.task.customer.name }}</span>
    </td>
    <td class=" px-7 py-3 whitespace-nowrap text-sm text-gray-500 text-right w-150p">
      <span :class="['inline-flex items-center justify-center text-white px-2 py-1 text-xs leading-none rounded', {
        'bg-success': invoice.status === 'sent',
        'bg-gray-400': invoice.status !== 'sent'
      }]">{{ invoice.status }}</span>
    </td>
    <td class=" px-7 py-3 whitespace-nowrap text-sm text-gray-500 text-right hidden xl:table-cell w-150p">
      <span>{{ invoice.total_vat_included }}</span> <span>€</span>
    </td>
    <td>
      <EditActions
          v-if="invoice.status === 'draft' && (showEditable || (selectedItem === String(invoice.id)))"
          :hideEdit="false"
          :item="invoice"
          itemType="invoice"
          @handleOpenDeleteItemModal="handleOpenDeleteItemModal"
          @handleSelectItem="handleSelectItem"
      />
    </td>
  </tr>
</template>

<script>
import EditActions from '@/components/Table/EditActions/EditActions';
import { mapActions } from 'vuex';

export default {
  name: 'Row',
  components: {
    EditActions
  },
  props: {
    invoice: {
      type: Object,
      required: true
    },
    selectedItem: String,
    selectedItems: Array
  },
  data() {
    return {
      showEditable: false,
      isOpenEditAction: false,
      checked: false
    }
  },
  methods: {
    ...mapActions({ setSelectInvoiceType: 'setSelectInvoiceType' }),
    mouseOver(showEditable) {
      this.showEditable = showEditable;
      this.isOpenEditAction = false;
    },
    mouseLeave(showEditable) {
      this.showEditable = showEditable;
      this.isOpenEditAction = false;
    },
    handleSelectItem(selectedItem) {
      this.$emit('handleSetSelectedItem', selectedItem);
      this.isOpenEditAction = true;
    },
    handleOpenDeleteItemModal(productId) {
      this.$emit('handleOpenDeleteItemModal', productId);
    },
    handleAdd() {
      this.setSelectInvoiceType('');
      const checked = !this.selectChecked(this.invoice);
      this.$emit('handleAddInvoice', { id: this.invoice.id, status: this.invoice.status, checked: checked });
    },
    selectChecked(invoice){
      const selectedInvoice = this.selectedItems.find(item => item.id ===invoice.id)
      return selectedInvoice && selectedInvoice.checked ? selectedInvoice.checked : false
    },
    goToInvoice(event) {
      if (event.target.closest('.edit_actions')) {
        return;
      }
      this.$router.push({ name: 'invoice-show', params: { id: this.invoice.id } })
    }
  }
}
</script>

<style scoped>

</style>
