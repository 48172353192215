<template>
    <div class="relative" v-if="!loading">
        <DeleteItemModal
            v-if="showModal"
            :title="$t('invoice.deleteInvoiceTitle')"
            :description="$t('invoice.deleteInvoiceDescription')"
            @handleCloseModal="handleCloseModal"
            @handleConfirm="deleteInvoice"
        />
        <DataTableSearch @handleSearchTable="handleSearchTable"/>
        <Table
            @handleSort="handleSort"
            @handleOpenDeleteItemModal="handleOpenDeleteItemModal"
            :dataTable="dataTable"
            @handleSelect="handleSelect"
        />
        <Pagination :count=count @handlePaginate="handlePaginate"/>
    </div>
</template>

<script>
import DataTableSearch from '@/components/DataTableSearch/DataTableSearch';
import Table from '@/components/Invoice/Table/Table';
import handleTableSort from '@/helpers/handleTableSort';
import Pagination from '@/components/Main/Pagination/Pagination';
import { mapActions, mapGetters } from 'vuex';
import DeleteItemModal from '@/components/Modals/DeleteItemModal/DeleteItemModal';
import { EventBus } from '@/eventBus/event-bus';

export default {
    name: 'InvoicesTable',
    components: { DeleteItemModal, Table, DataTableSearch, Pagination },
    data() {
        return {
            loading: true,
            count: 0,
            data: [],
            page: 1,
            dataTable: [],
            showModal: false,
            selectedInvoiceId: '',
            searchValue: '',
            searchTimer: null,
            sortBy: 'id',
            sortDesc: true
        };
    },
    computed: {
        ...mapGetters(['invoices'])
    },
    watch: {
        invoices(newValue) {
            this.count = newValue.meta.total;
            this.dataTable = [...newValue.data];
            this.loading = false;
        }
    },
    mounted() {
        this.handleGetInvoices({ page: this.page, search: this.searchValue });
        this.setSelectInvoiceType('');
    },
    methods: {
        ...mapActions({
            handleGetInvoices: 'fetchInvoices',
            setSelectInvoiceType: 'setSelectInvoiceType',
            fetchDeleteInvoice: 'fetchDeleteInvoice'
        }),
        handlePaginate(page, defaultPageSlice) {
            this.page = defaultPageSlice / (defaultPageSlice - page);
            this.handleGetInvoices({ page: this.page, search: this.searchValue, sortBy: this.sortBy, sortDesc: +this.sortDesc });
        },
        handleSort(key, sorting) {
          this.sortBy = key;
          this.sortDesc = !sorting;

          this.handleGetInvoices({ page: this.page, search: this.searchValue, sortBy: this.sortBy, sortDesc: +this.sortDesc });
        },
        async handleSearchTable(value) {
            this.searchValue = value;

            this.page = 1;
            EventBus.$emit('resetPage');

            clearTimeout(this.searchTimer);
            this.searchTimer = setTimeout(() => {
              this.handleGetInvoices({ page: 1, search: this.searchValue, sortBy: this.sortBy, sortDesc: +this.sortDesc });
            }, 500);
        },
        handleOpenDeleteItemModal(id) {
          this.selectedInvoiceId = id;
          this.showModal = true;
        },
        handleSelect(type) {
            this.setSelectInvoiceType(type);
        },
        handleCloseModal() {
          this.showModal = false;
        },
        deleteInvoice() {
          this.fetchDeleteInvoice(this.selectedInvoiceId).then(response => {
            this.toastMessage(this.$t('invoice.deleteSuccess'), 'success');
            this.dataTable = this.dataTable.filter(invoice => invoice.data.id !== this.selectedInvoiceId);
          }).catch(error => {
            this.toastMessage(error.message, 'error');
          });

          this.showModal = false;
        },
        toastMessage(message, type) {
          this.$toast.open({ message, type });
        }
    }
};
</script>

<style scoped>

</style>
