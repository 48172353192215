<template>
  <tr @mouseover="mouseOver(true)" @mouseleave="mouseLeave(false)">
    <td class="max-w-0 w-full px-6 py-4 break-words text-sm text-gray-900">
      {{ line.product.name }}
    </td>
    <td class="px-2 py-4 text-sm text-gray-500">
      {{ line.delivered_by.name }}
    </td>
    <td class="hidden px-2 py-4 text-sm text-gray-500 md:table-cell">
      {{ line.delivered_at | parseDateMain }}
    </td>
    <td class="px-2 py-4 text-sm text-gray-500">
      {{ line.checklist_item.item }}
    </td>
    <td class="px-2 py-4 text-center text-sm text-gray-500">
      {{ line.quantity }}
    </td>
    <td class="px-2 py-4 text-center text-sm text-gray-500">
      {{ line.unit }}
    </td>
    <td class="px-2 py-4 text-center text-sm text-gray-500">
      {{ line.unit_price }}
    </td>
    <td class="px-6 py-4 text-right text-sm text-gray-500">
      {{ line.vat }}
    </td>
    <td class="px-6 py-4 text-right text-sm text-gray-500">
      {{ line.total.total }}
    </td>
    <td class="pr-4 relative">
      <EditActions
          v-if="showEditable || (selectedItem === String(line.id))"
          :item="line"
          @handleOpenDeleteItemModal="$emit('handleOpenDeleteItemModal', $event)"
          @handleSelectItem="handleSelectItem"
      />
    </td>
  </tr>
</template>

<script>

import EditActions from '@/components/Invoice/EditActions/EditActions';

export default {
  name: 'Row',
  components: {
    EditActions
  },
  props: {
    line: {
      type: Object,
      required: true
    },
    selectedItem: String
  },
  data() {
    return {
      showEditable: false,
      isOpenEditAction: false
    }
  },
  methods: {
    mouseOver(showEditable) {
      this.showEditable = showEditable;
      this.isOpenEditAction = false;
    },
    mouseLeave(showEditable) {
      this.showEditable = showEditable;
      this.isOpenEditAction = false;
    },
    handleSelectItem(selectedItem) {
      this.$emit('handleSetSelectedItem', selectedItem);
      this.isOpenEditAction = true;
    }
  }
}
</script>

<style scoped>

</style>
