<template>
  <tr
      class="cursor-pointer"
  >
    <td class=" px-6 py-3 text-sm text-gray-500 whitespace-nowrap">
      <span>{{ invoiceLine.data.period }}</span>
    </td>
    <td class=" px-6 py-3 text-sm text-gray-500 whitespace-nowrap text-right truncate">
      <span>{{ issetDeliveredBy ? invoiceLine.data.delivered_by_name : $t('global.noUser') }}</span>
    </td>
    <td class=" px-6 py-3 text-sm text-gray-500 whitespace-nowrap text-right truncate">
      <span>{{ invoiceLine.data.product_name }}</span>
    </td>
    <td class=" px-6 py-3 text-sm text-gray-500 whitespace-nowrap text-right truncate">
      <span>{{ invoiceLine.data.product_quantity }}</span>
    </td>
  </tr>
</template>

<script>
import EditActions from '@/components/Table/EditActions/EditActions';
import moment from 'moment';

export default {
  name: 'Row',
  components: {
    EditActions
  },
  props: {
    invoiceLine: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showEditable: false,
      isOpenEditAction: false,
      checked: false
    }
  },
  computed: {
    issetDeliveredBy() {
      return this.invoiceLine.data.delivered_by
    }
  }
}
</script>

<style scoped>

</style>
