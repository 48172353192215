<template>
  <div class="invoice_total">
    <table class="w-full">
      <tbody>
      <tr>
        <td class="text-sm font-medium text-gray-500">{{ $t('task.subtotal') }}</td>
        <td v-if="total.subtotal" class="text-right text-sm text-gray-900">{{ `${total.subtotal} €` }}</td>
      </tr>
      <tr>
        <td class="text-sm font-medium text-gray-500">{{ $t('task.vat') }}</td>
        <td v-if="total.vat || total.vat === 0" class="text-right text-sm text-gray-900">{{ `${total.vat && total.vat.toFixed(2)} €` }}</td>
      </tr>
      <tr>
        <td class="text-sm font-medium text-gray-500">{{ $t('task.total') }}</td>
        <td v-if="total.total" class="text-right text-sm text-gray-900">{{ `${total.total} €` }} </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'InvoiceTotal',
  props: {
    total:{
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
