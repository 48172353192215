<template>
  <div class="relative">
<!--    <DeleteItemModal
        v-if="showModal"
        :title="$t('invoice.deleteInvoiceTitle')"
        :description="$t('invoice.deleteInvoiceDescription')"
        @handleCloseModal="handleCloseModal"
        @handleConfirm="deleteInvoice"
    />-->
    <DataTableSearch @handleSearchTable="handleSearchTable"/>
    <InvoiceSummaryTable
        @handleSort="handleSort"
        @handleOpenDeleteItemModal="handleOpenDeleteItemModal"
        :dataTable="dataTable"
        @handleSelect="handleSelect"
        @filtersUpdate="handleFiltersUpdate"
    />
    <Pagination :count="count" @handlePaginate="handlePaginate"/>
  </div>
</template>

<script>
import DataTableSearch from '@/components/DataTableSearch/DataTableSearch';
import InvoiceSummaryTable from '@/components/Invoice/Table/InvoiceSummaryTable';
import handleTableSort from '@/helpers/handleTableSort';
import Pagination from '@/components/Main/Pagination/Pagination';
import { mapActions, mapGetters } from 'vuex';
import DeleteItemModal from '@/components/Modals/DeleteItemModal/DeleteItemModal';
import { EventBus } from '@/eventBus/event-bus';

export default {
  name: 'InvoicesSummaryTable',
  components: { DeleteItemModal, InvoiceSummaryTable, DataTableSearch, Pagination },

  data() {
    return {
      loading: true,
      count: 0,
      data: [],
      page: 1,
      dataTable: [],
      showModal: false,
      selectedInvoiceId: '',
      searchValue: '',
      searchTimer: null,
      sortBy: 'id',
      sortDesc: true,
      year: null,
      month: null,
      deliveredBy: null
    };
  },
  computed: {
    ...mapGetters(['summaries'])
  },
  watch: {
    summaries(newValue) {
      this.count = newValue.meta.total;
      this.dataTable = [...newValue.data];
      this.loading = false;

      if (! this.dataTable.length) {
        this.toastMessage(this.$t('global.noResultsFound'), 'error');
      }
    }
  },
  mounted() {
    //this.handleInvoiceSummaries({ page: this.page, search: this.searchValue }); //important, add data here
  },
  methods: {
    ...mapActions({
      handleInvoiceSummaries: 'fetchInvoiceSummaries',
      setSelectInvoiceType: 'setSelectInvoiceType',
      fetchDeleteInvoice: 'fetchDeleteInvoice'
    }),
    handlePaginate(page, defaultPageSlice) {
      this.page = defaultPageSlice / (defaultPageSlice - page);
      this.handleInvoiceSummaries({
        page: this.page,
        search: this.searchValue,
        sortBy: this.sortBy,
        sortDesc: +this.sortDesc,
        year: this.year,
        month: this.month,
        deliveredBy: this.deliveredBy
      });
    },
    handleSort(key, sorting) {
      this.sortBy = key;
      this.sortDesc = !sorting;

      this.handleInvoiceSummaries({
        page: this.page,
        search: this.searchValue,
        sortBy: this.sortBy,
        sortDesc: +this.sortDesc,
        year: this.year,
        month: this.month,
        deliveredBy: this.deliveredBy
      });
    },
    async handleSearchTable(value) {
      this.searchValue = value;

      this.page = 1;
      EventBus.$emit('resetPage');

      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
        this.handleInvoiceSummaries({
          page: this.page,
          search: this.searchValue,
          sortBy: this.sortBy,
          sortDesc: +this.sortDesc,
          year: this.year,
          month: this.month,
          deliveredBy: this.deliveredBy
        });
      }, 500);
    },
    handleOpenDeleteItemModal(id) {
      this.selectedInvoiceId = id;
      this.showModal = true;
    },
    handleSelect(type) {
      this.setSelectInvoiceType(type);
    },
    handleCloseModal() {
      this.showModal = false;
    },
    deleteInvoice() {
      this.fetchDeleteInvoice(this.selectedInvoiceId).then(response => {
        this.toastMessage(this.$t('invoice.deleteSuccess'), 'success');
        this.dataTable = this.dataTable.filter(invoice => invoice.data.id !== this.selectedInvoiceId);
      }).catch(error => {
        this.toastMessage(error.message, 'error');
      });

      this.showModal = false;
    },
    toastMessage(message, type) {
      this.$toast.open({ message, type });
    },
    handleFiltersUpdate(data) {
      this.year = data.year;
      this.month = data.month;
      this.deliveredBy = data.deliveredBy;
      this.page = 1;
      !data.noUpdate && this.handleInvoiceSummaries({
        page: this.page,
        search: this.searchValue,
        sortBy: this.sortBy,
        sortDesc: +this.sortDesc,
        year: this.year,
        month: this.month,
        deliveredBy: this.deliveredBy
      });
    }
  }
};
</script>

<style scoped>

</style>
