<template>
  <div class="shadow sm:hidden">
    <ul class="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
      <li v-for="line in lines" :key="line.id">
        <div class="block px-4 py-4 bg-white hover:bg-gray-50 flex justify-between">
          <div class="flex-1">
            <div class="flex">
              <div class="text-sm font-medium text-gray-500">
                {{ $t('invoice.product') }}
              </div>
              <div class="text-sm text-gray-900 ml-3">
                {{ line.product.name ? line.product.name : '' }}
              </div>
            </div>
            <div class="flex">
              <div class="text-sm font-medium text-gray-500">
                {{ $t('invoice.deliveredBy') }}
              </div>
              <div class="text-sm text-gray-900 ml-3">
                {{ line.delivered_by.name ? line.delivered_by.name  : ''  }}
              </div>
            </div>
            <div class="flex">
              <div class="text-sm font-medium text-gray-500">
                {{ $t('invoice.deliveredAt') }}
              </div>
              <div class="text-sm text-gray-900 ml-3">
                {{ line.delivered_at }}
              </div>
            </div>
            <div class="flex">
              <div class="text-sm font-medium text-gray-500">
                {{ $t('invoice.description') }}
              </div>
              <div class="text-sm text-gray-900 ml-3">
                {{ line.description }}
              </div>
            </div>
            <div class="flex">
              <div class="text-sm font-medium text-gray-500">
                {{ $t('invoice.quantity') }}
              </div>
              <div class="text-sm text-gray-900 ml-3">
                {{ line.quantity }}
              </div>
            </div>
            <div class="flex">
              <dt class="text-sm font-medium text-gray-500">
                {{ $t('invoice.unit') }}
              </dt>
              <dd class="text-sm text-gray-900 ml-3">
                Margot Foster
              </dd>
            </div>
            <div class="flex">
              <dt class="text-sm font-medium text-gray-500">
                {{ $t('invoice.unitPrice') }}
              </dt>
              <dd class="text-sm text-gray-900 ml-3">
                Margot Foster
              </dd>
            </div>
            <div class="flex">
              <dt class="text-sm font-medium text-gray-500">
                {{ $t('invoice.vat') }}
              </dt>
              <dd class="text-sm text-gray-900 ml-3">
                Margot Foster
              </dd>
            </div>
            <div class="flex">
              <dt class="text-sm font-medium text-gray-500">
                Total
              </dt>
              <dd class="text-sm text-gray-900 ml-3">
                Margot Foster
              </dd>
            </div>
          </div>

          <div>
            <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"></path>
            </svg>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'MobileTable',
  props: {
    lines: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
