<template>
  <tbody class="bg-white divide-y divide-gray-100">
  <Row
      v-for="invoice in dataTable"
      :key="invoice.id"
      :invoice="invoice.data"
      :selectedItem="String(selectedItem)"
      :selectedItems = sendingInvoices
      @handleSetSelectedItem="handleSetSelectedItem"
      @handleAddInvoice="handleAddInvoice"
      @handleOpenDeleteItemModal="$emit('handleOpenDeleteItemModal', $event)"
  />
  </tbody>
</template>

<script>
import EditActions from '@/components/Table/EditActions/EditActions';
import Row from '@/components/Invoice/Table/TableBody/Row/Row';
import { EventBus } from '@/eventBus/event-bus';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TableBody',
  components: {
    Row,
    EditActions
  },
  props: {
    dataTable: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedItem: '',
      sendingInvoices: []
    }
  },
  computed: {
    ...mapGetters(['selectInvoiceType'])
  },
  watch: {
    selectInvoiceType(value) {
      this.dataTable.map(item => {
        const invoice = { id: item.data.id, status: item.data.status, checked: true };
        switch (value) {
          case 'all':
            this.handleAddInvoice(invoice);
            break;
          case 'draft':
            if (invoice.status === 'draft') {
              this.handleAddInvoice(invoice);
            }
            break;
          case 'unselect':
            this.sendingInvoices = [];
            break;
          case 'none':
            this.sendingInvoices = [];
            break;
          default:
        }
      })
    }
  },
  mounted() {
    EventBus.$on('sendSelectedInvoices', () => {
      this.fetchSelectedInvoices(this.sendingInvoices).then((r) => {
        this.f({ page:1, search:'' })
      })
    });
  },
  methods: {
    ...mapActions({
      fetchSelectedInvoices: 'fetchSelectedInvoices',
      f: 'fetchInvoices'
    }),
    handleSetSelectedItem(invoiceId) {
      this.selectedItem = invoiceId;
    },
    handleAddInvoice(invoice) {
      if (invoice.checked) {
        this.sendingInvoices.push(invoice);
      } else {
        this.sendingInvoices = this.sendingInvoices.filter(sendingInvoice => sendingInvoice.id !== invoice.id);
      }
    }
  },
  destroyed() {
      EventBus.$off('sendSelectedInvoices');
  }
}
</script>

<style scoped>

</style>
