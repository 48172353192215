<template>
  <AppLayout>
    <router-view />
  </AppLayout>
</template>

<script>
import AppLayout from '@/components/Layout/AppLayout';
export default {
  name: 'Invoices',
  props:{
    invoice:{
      type: Object
    }
  },
  components: { AppLayout }
}
</script>

<style scoped>

</style>
