<template>
  <div>
    <DeleteItemModal
        v-if="showModal"
        :title="$t('invoice.deleteInvoiceLineModal.title')"
        :description="$t('invoice.deleteInvoiceLineModal.description')"
        @handleCloseModal="handleCloseModal"
        @handleConfirm="deleteInvoice"
    />
    <div class="px-4 sm:px-6">
      <h1 class="text-lg font-medium text-gray-900 mb-2">{{ $t('invoice.invoiceRows') }}</h1>
      <MobileTable v-if="invoiceLInes.length" :lines="invoiceLInes"/>
      <DeskTopTable
          v-if="invoiceLInes.length"
          :lines="invoiceLInes"
          @handleOpenDeleteCustomerModal="handleOpenDeleteCustomerModal"/>
    </div>
  </div>
</template>

<script>
import DeskTopTable from '@/components/Invoice/InvoiceRows/DeskTopTable';
import MobileTable from '@/components/Invoice/InvoiceRows/MobileTable';
import { mapActions } from 'vuex';
import DeleteItemModal from '@/components/Modals/DeleteItemModal/DeleteItemModal';


export default {
  name: 'InvoiceRows',
  components: {
    MobileTable,
    DeskTopTable,
    DeleteItemModal
  },
  props: {
    lines: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      invoiceLInes: [],
      showModal: false,
      selectedInvoiceId: ''
    }
  },
  mounted() {
    this.invoiceLInes = this.lines;
  },
  methods: {
    ...mapActions(['fetchDeleteInvoiceLine']),
    handleOpenDeleteCustomerModal(invoiceId) {
      this.showModal = true;
      this.selectedInvoiceId = invoiceId
    },
    handleCloseModal() {
      this.showModal = false;
    },
    deleteInvoice() {
      this.invoiceLInes = this.invoiceLInes.filter(lines => lines.id !== this.selectedInvoiceId);
      this.fetchDeleteInvoiceLine(this.selectedInvoiceId);
      this.handleCloseModal();
    }
  }
}
</script>

<style scoped>

</style>
