<template>
  <div class="align-middle inline-block min-w-full border-b border-gray-200">
    <div class="ml-5 flex gap-4">
<!--      <select v-model="year">
        <option v-for="year in lastYears" :value="year">{{ year }}</option>
      </select>-->

      <Dropdown
          v-model="year"
          :items="lastYears"
          class="w-32"
          :label="$t('global.year')"
      />

      <Dropdown
          v-model="month"
          :items="months"
          class="w-48"
          :label="$t('global.month')"
      />

      <Dropdown
          v-model="deliveredBy"
          :items="usersWithoutPagination"
          class="w-64"
          :label="$t('global.deliveredBy')"
          :optional="true"
      />

      <button class="disabled:opacity-50 inline-flex items-center justify-center h-10 mt-7 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md truncate text-white focus:outline-none bg-primary hover:opacity-50" @click="filtersUpdate">{{ $t('global.submit') }}</button>
    </div>

    <table class="mt-4 w-full table-fixed">
      <InvoiceSummaryTableHead :result="!!dataTable.length" @handleSort="handleSort" @handleSelect="handleSelect" />
      <InvoiceSummaryTableBody v-if="filtered" :dataTable="dataTable" :lines="dataTable" />
    </table>
  </div>
</template>

<script>
import InvoiceSummaryTableHead from '@/components/Invoice/Table/TableHead/InvoiceSummaryTableHead';
import InvoiceSummaryTableBody from '@/components/Invoice/Table/TableBody/InvoiceSummaryTableBody';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import Dropdown from '@/components/Common/Dropdown';

export default {
  name: 'InvoiceSummaryTable',
  components: {
    Dropdown,
    InvoiceSummaryTableHead,
    InvoiceSummaryTableBody
  },
  props: {
    dataTable: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      filtered: false,
      year: moment().format('YYYY'),
      month: moment().format('M'),
      deliveredBy: null,
      months: [
        {
          id: '1',
          name: this.$t('global.january')
        },
        {
          id: '2',
          name: this.$t('global.february')
        },
        {
          id: '3',
          name: this.$t('global.march')
        },
        {
          id: '4',
          name: this.$t('global.april')
        },
        {
          id: '5',
          name: this.$t('global.may')
        },
        {
          id: '6',
          name: this.$t('global.june')
        },
        {
          id: '7',
          name: this.$t('global.july')
        },
        {
          id: '8',
          name: this.$t('global.august')
        },
        {
          id: '9',
          name: this.$t('global.september')
        },
        {
          id: '10',
          name: this.$t('global.october')
        },
        {
          id: '11',
          name: this.$t('global.november')
        },
        {
          id: '12',
          name: this.$t('global.december')
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['usersWithoutPagination']),
    lastYears() {
      const years = [];

      for(let i = 0; i < 11; i++) {
        const year = moment().subtract(i, 'year').format('YYYY');
        years.push({
          id: year,
          name: year
        });
      }

      return years;
    }
  },
  methods: {
    ...mapActions({
      handleGetUsersWithoutPagination: 'getUsersWithoutPagination',
      handleSetBreadCrumbItems: 'setBreadCrumbItems',
      handleResetBreadCrumbItems: 'resetBreadCrumbItems'
    }),
    handleSort(key, sorting) {
      this.$emit('handleSort', key, sorting);
    },
    handleSelect(key) {
      this.$emit('handleSelect', key);
    },
    filtersUpdate() {
      this.filtered = true;
      this.$emit('filtersUpdate', { year: this.year, month: this.month, deliveredBy: this.deliveredBy })
    },
    toastMessage(message, type = 'success') {
      this.$toast.open({ message, type });
    }
  },
  mounted() {
    this.handleGetUsersWithoutPagination();
    this.handleSetBreadCrumbItems({ index: 1, name: this.$t('global.summary'), to: '' });
    this.$emit('filtersUpdate', { year: this.year, month: this.month, deliveredBy: this.deliveredBy, noUpdate: true })
  }
}
</script>

<style scoped>

</style>
